<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;" v-if="classifyList && classifyList.length">
            <div slot="header" class="my-header">
                <div class="bold">分类管理</div>
                <div>
                    <el-button type="default" size="mini" @click="addClassify">添加分类</el-button>
                    <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasList">
                <template v-for="(v,i) of classifyList">
                    <el-button-group class="m-l-15 m-b-15">
                        <el-button type="" size="mini">{{v.vrAlbumName}}</el-button>
                        <el-button type="" size="mini" icon="el-icon-edit-outline" @click="editClassify(v)"></el-button>
                        <el-button type="" size="mini" icon="el-icon-delete" @click="delMessageBox('delEnterpriseVrAlbum',v,i)"></el-button>
                    </el-button-group>
                </template>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;text-align: center;" v-else>
            <div>
                <img src="@/assets/images/photo/class_not.png">
                <div class="f14 c-2 m-b-20">
                    当前您还没有相册分类哦！
                </div>
                <el-button type="default" size="mini" @click="addClassify">立即添加</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;">
            <div slot="header" class="my-header">
                <div class="bold">VR视频</div>
                <div>
                    <el-select v-model="enterpriseVrAlbumId" placeholder="请选择" size="mini" class="m-r-10">
                        <el-option v-for="item in classifyList" :key="item.id" :label="item.vrAlbumName" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">添加网络VR</el-button>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">添加本地VR</el-button>
                    <!-- <el-button type="default" size="mini">全部删除</el-button> -->
                    <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
                    <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
                    <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasList">
                <!--              <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-for="(v,i) of list" :label="v.name" :name="v.id"></el-tab-pane>
                </el-tabs> -->
                <div class="items" v-if="1">
                    <div v-for="(v,i) of 10" class="item m-b-35" :key="i">
                        <img src="@/assets/images/fur1.png">
                        <div class="m-t-15">
                            <div class="">
                                <el-checkbox v-model="checked" class="f16 c-3 bold">沙发相册1</el-checkbox>
                            </div>
                            <div class="f14 c-2 m-t-10">6月15日</div>
                            <div class="m-t-10 p-b-20">
                                <el-button size="mini">修改名称</el-button>
                                <el-button size="mini">删除VR</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center">
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">
                        当前您还没有相册哦！
                    </div>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">立即创建</el-button>
                </div>
            </div>
        </el-card>

        <!-- 编辑VR分类 添加VR分类 -->
        <el-dialog width="500px" :title="classify.id?'编辑VR分类':'添加VR分类'" :visible.sync="classifyDialogFormVisible">
            <el-form :model="classify" ref="classifyForm" :rules="classifyFormRule" label-width="120px">
                <el-form-item label="封面" prop="vrAlbumPhoto">
                    <upload-image @uploadImageBack="uploadImageBack" class="avatar-uploader" size="mini">
                        <img v-if="classify.vrAlbumPhoto" :src="$oucy.ossUrl+classify.vrAlbumPhoto" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </upload-image>
                </el-form-item>
                <el-form-item label="名称" prop="vrAlbumName">
                    <el-input v-model="classify.vrAlbumName" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        
         <el-dialog width="500px" title="添加本地VR" :visible.sync="VRDialogFormVisible">
            <el-form :model="classifyForm" ref="classifyForm" :rules="classifyFormRule" label-width="120px">
                <el-form-item label="分类" prop="category">
                    <el-upload
                      class="avatar-uploader"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload">
                      <img v-if="imageUrl" :src="imageUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
    
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="name" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseVrAlbum, enterpriseVrAlbumItem } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store"
import uploadImage from "@/components/uploadImage.vue"

export default {
    name: "Photo",
    components: {
        uploadImage,
    },
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                vrAlbumName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                vrAlbumPhoto: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasList: true,
            list: [{ name: '家具分类1', id: '1' }, { name: '家具分类2', id: '2' }],
            activeName: '1',
            classifyForm: '',
            classify: '',
            name: '',
            checked: '',
            value: '',

            // 分类册列表
            classifyDialogFormVisible: !true,
            classifyList:[],
            classify: {
                vrAlbumName: null,
                vrAlbumPhoto: null,
            },

            enterpriseVrAlbumId:null,

            // VR
            VRDialogFormVisible:true,
            VR:{
                itemName:null,
                itemUrl:null,
            }
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.getAllEnterpriseVrAlbumsClient()
    },
    methods: {
        handleClick() {},
        // 点击保存分类册
        submitClassifyForm() {

            const me = this;
            me.$refs["classifyForm"].validate((valid) => {
                if (valid) {
                    this.classify.enterpriseId=this.enterpriseId
                    if(this.classify.id){
                        this.updateEnterpriseVrAlbum()
                    }else{
                        this.addEnterpriseVrAlbum()
                    }
                }
            })
            
        },
        delMessageBox(fu, v,i,text=null) {
            this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 增加分类
        addClassify(){
            this.classify={
                vrAlbumName: null,
                vrAlbumPhoto: null,
            }
            this.oPenClassifyDialogFormVisible()
        },
        // 编辑分类
        editClassify(v){
            this.classify=Object.assign({},v)
            this.oPenClassifyDialogFormVisible()
        },
        // 打开添加分类
        oPenClassifyDialogFormVisible() {
            this.classifyDialogFormVisible = true
        },
        // 关闭添加分类
        closeClassifyDialogFormVisible(v) {
            this.classifyDialogFormVisible = false
        },
        uploadImageBack(v) {
            this.classify.vrAlbumPhoto=v
        },
        // 新增企业vr册
        addEnterpriseVrAlbum() {
            enterpriseVrAlbum.addEnterpriseVrAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseVrAlbumsClient()
                this.closeClassifyDialogFormVisible()
            })
        },
        // 删除企业vr册
        delEnterpriseVrAlbum(v,i) {
            enterpriseVrAlbum.delEnterpriseVrAlbum({id:v.id,enterpriseId: this.enterpriseId}).then(res => {
                this.classifyList.splice(i,1)
                console.log(res)
                // 如果删除的是当前选中的分类
                if(this.enterpriseVrAlbumId==v.id){
                    if(this.classifyList.length>0){
                        this.enterpriseVrAlbumId=this.classifyList[0].id
                        this.getAllEnterpriseVrAlbumItems()
                    }
                }
            })
        },
        // 修改企业vr册
        updateEnterpriseVrAlbum() {
            enterpriseVrAlbum.updateEnterpriseVrAlbum(this.classify).then(res => {
                console.log(res)
                this.getAllEnterpriseVrAlbumsClient()
                this.closeClassifyDialogFormVisible()

            })
        },
        // 查询用户的vr册
        getAllEnterpriseVrAlbumsClient() {
            enterpriseVrAlbum.getAllEnterpriseVrAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                if(res){
                    this.classifyList=res
                }
                console.log(res)
            })
        },
        // 新增企业vr
        addEnterpriseVrAlbumItem() {},
        // 删除企业vr
        delEnterpriseVrAlbumItem() {},
        // 修改企业vr
        updateEnterpriseVrAlbumItem() {},
        // 查询用户的vr
        getAllEnterpriseVrAlbumItems() {},
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
</style>